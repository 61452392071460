<template>
    <input
        :aria-describedby="hasHelper ? getHelperId : null"
        :class="inputClassName"
        :style="cssProps"
        :aria-disabled="isDisabled ? 'true' : null"
        :id="id"
        :name="name"
        :value="text || modelValue"
        type="text"
        :maxlength="maxlength"
        @input="onInput"
    >
</template>

<script>

import themingUtils from '@/utils/theming/themingUtils';

export default {
    name: 'NebulaInput',

    mixins: [themingUtils],

    props: {
        hasHelper: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        id: {
            type: [String, Number],
        },
        maxlength: {
            type: [Number],
            default: null,
        },
        name: {
            type: String,
        },
        size: {
            type: String,
            default: 'm', // Options: s, m, l
            enum: ['xs', 's', 'm', 'l'], // for docs site
        },
        status: {
            type: String,
            default: null, // Options: success, error
        },
        text: {
            type: String,
        },
        modelValue: {
            type: String,
        },
    },
    emits: [
        'input',
        'update:model-value',
    ],
    computed: {
        inputClassName() {
            const className = [
                'nebula-input',
                `nebula-input--${this.size}`,
                {
                    'nebula-input--error': this.status === 'error',
                    'nebula-input--success': this.status === 'success',
                },
            ];
            return className;
        },
        getHelperId() {
            return `helper-text__${this.id}`;
        },
    },
    methods: {
        onInput(e) {
            this.$emit('update:model-value', e.target.value); // standard event for Vue 3 v-model
            this.$emit('input', e.target.value); // older code may still listen for this
        },
    },
};
</script>

<style lang="stylus">
:root {
    --nebula-input-background: $nebula-color-white;
    --nebula-input-background-disabled: $nebula-color-interface-blue-100;
    --nebula-input-border-width: 1px;
    --nebula-input-border-style: solid;
    --nebula-input-border-color: $nebula-color-interface-blue-300;
    --nebula-input-border-color-active: $nebula-text-color-link-hover;
    --nebula-input-border-color-error: $nebula-color-error;
    --nebula-input-border-color-success: $nebula-color-success;
    --nebula-input-border-color-disabled: $nebula-color-interface-blue-100;
    --nebula-input-border-radius: $nebula-border-radius-small;
    --nebula-input-box-shadow: 0 0 0 3px rgba($nebula-text-color-link-default, 0.3);
    --nebula-input-box-shadow-error: 0 0 0 3px rgba($nebula-color-error, .3);
    --nebula-input-box-shadow-success: 0 0 0 3px rgba($nebula-color-success, .3);
    --nebula-input-font-family: $nebula-font-family-display;
    --nebula-input-font-size: $nebula-font-size-body-1;
    --nebula-input-height: $nebula-space-5x;
    --nebula-input-margin: 0 0 $nebula-space-2x;
    --nebula-input-max-width: 500px;
    --nebula-input-padding: 11px $nebula-space-2x;
    --nebula-input-text-color: $nebula-color-neutral-400;
    --nebula-input-text-color-disabled: $nebula-color-interface-blue-300;
    --nebula-input-transition: border, box-shadow $nebula-transition-default;
    --nebula-input-width: 100%;
}

.nebula-input {
    -webkit-appearance: none;
    appearance: none;
    background: var(--nebula-input-background);
    border-width: var(--nebula-input-border-width);
    border-style: var(--nebula-input-border-style);
    border-color: var(--nebula-input-border-color);
    border-radius: var(--nebula-input-border-radius);
    color: var(--nebula-input-text-color);
    display: block;
    font-family: var(--nebula-input-font-family);
    font-size: var(--nebula-input-font-size);
    height: var(--nebula-input-height);
    margin: var(--nebula-input-margin);
    max-width: var(--nebula-input-max-width);
    padding: var(--nebula-input-padding);
    transition: var(--nebula-inpute-transition);
    width: var(--nebula-input-width);

    &:active, &:focus-visible {
        border-color: var(--nebula-input-border-color-active);
        box-shadow: var(--nebula-input-box-shadow);
        outline: 0;
    }

    &--error {
        border-color: var(--nebula-input-border-color-error);
        box-shadow: var(--nebula-input-box-shadow-error);

        &:active, &:focus-visible {
            border-color: var(--nebula-input-border-color-error);
            box-shadow: var(--nebula-input-box-shadow-error);
        }
    }

    &--success {
        border-color: var(--nebula-input-border-color-success);
        box-shadow: var(--nebula-input-box-shadow-success);

        &:active, &:focus-visible {
            border-color: var(--nebula-input-border-color-success);
            box-shadow: var(--nebula-input-box-shadow-success);
        }
    }

    &:disabled,
    &[aria-disabled="true"] {
        background-color: var(--nebula-input-background-disabled);
        border-color: var(--nebula-input-border-color-disabled);
        color: var(--nebula-input-text-color-disabled);
        caret-color: transparent;
        pointer-events: none;

        &:active, &:focus-visible {
            border-color: var(--nebula-input-border-color-disabled);
        }
    }

    &--hidden {
        visibility: hidden;
    }

    &--xs {
        --nebula-input-font-size: $nebula-font-size-caption;
        --nebula-input-height: $nebula-space-3x;
        --nebula-input-padding: $nebula-space-half $nebula-space-1x;
    }

    &--s {
        --nebula-input-font-size: $nebula-font-size-body-2;
        --nebula-input-height: $nebula-space-4x;
        --nebula-input-padding: $nebula-space-1x $nebula-space-2x;
    }

    &--l {
        --nebula-input-height: $nebula-space-6x;
        --nebula-input-padding: 15px $nebula-space-2x;
    }
}

</style>
