<template>
    <div :class="helperClassName">
        <NebulaIcon
            v-if="icon"
            class="nebula-form-helper__icon"
            :symbolId="icon"
            size="s"
        />
        <span
            class="nebula-form-helper__text"
            :id="helperId"
            v-html="text"
        />
    </div>
</template>

<script>
import NebulaIcon from '@/components/Icon/NebulaIcon.vue';

export default {
    name: 'NebulaFormHelper',
    components: {
        NebulaIcon,
    },
    props: {
        icon: {
            type: String,
            default: null,
        },
        helperId: {
            type: [String, Number],
            required: true,
        },
        status: {
            type: String,
            default: null, // Options: success, error
            enum: ['success', 'error'],
        },
        text: {
            type: String,
            default: null,
        },
    },
    computed: {
        helperClassName() {
            const className = [
                'nebula-form-helper',
                {
                    'nebula-form-helper--has-icon': this.icon,
                    'nebula-form-helper--error': this.status === 'error',
                    'nebula-form-helper--success': this.status === 'success',
                },
            ];
            return className;
        },
    },
};
</script>

<style lang="stylus">

.nebula-form-helper {
    display: flex;
    font-size: $nebula-font-size-caption;
    line-height: 1.125rem;
    margin: $nebula-space-1x 0;

    &__icon {
        min-width: $nebula-space-2x;
    }

    &__text {
        padding: 0 $nebula-space-half;
    }

    &--error {
        .nebula-form-helper__icon {
            fill: $nebula-color-error;
        }

        .nebula-form-helper__text {
            color: $nebula-color-error;
        }
    }

    &--success {
        .nebula-form-helper__icon {
            fill: $nebula-color-success;
        }

        .nebula-form-helper__text {
            color: $nebula-color-success;
        }
    }
}

</style>
