<template>
    <label
        class="nebula-label"
        :for="inputId"
    >
        <span v-if="responsiveLabelText">
            <span class="nebula-label__responsive-text">
                {{ responsiveLabelText }}
            </span>
            <span class="nebula-label__desktop-text">
                {{ labelText }}
            </span>
        </span>
        <template v-else>
            {{ labelText }}
        </template>
        <span v-if="isRequired" class="nebula-label__required">
            {{ requireText }}
        </span>
    </label>
</template>

<script>
export default {
    name: 'NebulaLabel',
    props: {
        inputId: {
            type: [String, Number],
        },
        labelText: {
            type: String,
        },
        responsiveLabelText: {
            type: String,
            default: null,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        requiredLabelText: {
            type: String,
            default: null,
        },
    },

    computed: {
        requireText() {
            return this.requiredLabelText ? `(${this.requiredLabelText})` : '*';
        },
    },
};
</script>

<style lang="stylus">

.nebula-label {
    display: block;
    font-size: $nebula-font-size-body-2;
    font-weight: 500;
    margin-bottom: $nebula-space-1x;

    &__responsive-text {
        display: inline;
    }

    &__desktop-text {
        display: none;
    }

    &__required {
        color: $nebula-color-error;
    }
}

@media (min-width: $nebula-breakpoints-tablet-portrait) {
    .nebula-label {
        &__responsive-text {
            display: none;
        }

        &__desktop-text {
            display: inline;
        }
    }
}

</style>
